import { toast, ToastOptions } from 'react-toastify';

export const notifyError = (message, options: ToastOptions={}) => {
  toast.error(message, {
    position: 'top-left',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};
