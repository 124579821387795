import { makeStyles } from '@material-ui/core';
import Player from '@vimeo/player';
import { useEffect, useRef, useState } from 'react';
import { getDeviceInfo } from '../../../hooks/useDeviceInfo2';
import { getVideoDimensions } from '../../games/iFrameGameMaster/utils/gameUtils';
import {
  getScreenOrientation,
  setFullScreen,
} from '../../games/iFrameGameMaster/utils/orientationAndFullscreen';

import IPhoneOrientationMessage from '../../pages/pages-ui/IPhoneOrientationMessage';
import { VideoContainer } from './styles/VideoContainer';


const useStyles = makeStyles({
  videoIframe: {
    border: 'none',
  },
});

export default function FirstVideo({ onVideoComplete, url }) {
  const classes = useStyles();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const osName = getDeviceInfo();
  const screenOrientation = getScreenOrientation();
  const [showResumeScreen, setShowResumeScreen] = useState(
    osName === 'iOS' && screenOrientation === 'portrait'
  );

  const setIFrameDimensions = () => {
    const { iHeight, iWidth, paddingTop } = getVideoDimensions();
    iframeRef.current.width = iWidth.toString();
    iframeRef.current.height = iHeight.toString();
  };

  useEffect(() => {
    if (iframeRef.current) {
      setIFrameDimensions();
    }

    // this might not be needed for iOS
    setFullScreen()
      .then(() => {
        if (!iframeRef.current) return;
        setIFrameDimensions();
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const player = new Player(iframeRef.current);

    if (osName === 'iOS' && getScreenOrientation() === 'portrait') {
      player.pause();
    } else {
      player.play();
    }
    //!Temp for testing ---remove it
    // onVideoComplete();
    player.on('ended', () => {
      onVideoComplete();
    });
    // player.on('error', (err) => {
    //   console.log(err.message);
    //   alert(JSON.stringify(err));
    //   notifyError(JSON.stringify(err));
    //   onVideoComplete();
    // });

    const onOrientationChange = () => {
      // alert('before scroll')
      // setTimeout(() => {
      //   window.scrollTo(0, 0);
      // }, 1000);

      const onOrientationChangeEnds = (e) => {
        window.scrollTo(0, 0);

        if (!iframeRef.current) return;

        setIFrameDimensions();

        window.removeEventListener('resize', onOrientationChangeEnds);
      };
      // we are adding this listener to get the updated values for the height and width
      // also this is fire when the orientation change is complete
      window.addEventListener('resize', onOrientationChangeEnds);

      // re-set the width and height of the ifFrame
      // have to give timeout as without it, taking old values
      // setTimeout(() => {
      //   if (!iframeRef.current) return;
      //   iframeRef.current.width = window.innerWidth.toString();
      //   iframeRef.current.height = window.innerHeight.toString();
      // }, 50);

      if (osName !== 'iOS') {
        player.play();
        return;
      }
      // pausing video in the portrait mode and playing again when in the landscape mode.
      if (getScreenOrientation() === 'portrait') {
        player.pause();
      } else {
        player.play();
      }

      setShowResumeScreen(getScreenOrientation() === 'portrait');
    };

    window.addEventListener('orientationchange', onOrientationChange);

    return () => {
      player.off('ended');
      return window.removeEventListener(
        'orientationchange',
        onOrientationChange
      );
    };
  }, []);

  const { iHeight, iWidth, paddingTop } = getVideoDimensions();

  return (
    <div>
      {showResumeScreen && <IPhoneOrientationMessage />}

      <VideoContainer
        style={{
          display: showResumeScreen ? 'none' : 'flex',
          justifyContent: 'center',
          paddingTop: paddingTop,
        }}
      >
        {/* @ts-ignore */}

        <iframe
          className={classes.videoIframe}
          ref={iframeRef}
          src={`${url}?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;&autoplay=1&amp;player_id=0&amp;app_id=58479`}
          allow="autoplay; fullscreen; picture-in-picture"
          title="Talent Litmus Music!"
          width={iWidth}
          height={iHeight}
        ></iframe>
      </VideoContainer>
    </div>
  );
}

/* 
    onReady={() => {
          setVideoData((state) => ({ ...state, isLoaded: true }));
        }}
*/

/* <iframe src="https://player.vimeo.com/video/532284775?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Taking-Flight.mp4"></iframe>
  <Vimeo
        video={videoData.data.url}
        autoplay muted={true}
        onReady={reqFullScreen}
        id="videoElement"
        onEnd={onComplete}
      />
      
      
          <CloseButton variant="contained" onClick={onVideoComplete}>
          Close
        </CloseButton>
*/
