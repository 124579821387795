import { makeStyles } from '@material-ui/core';
import React from 'react';
import DynamicContainer from '../shared/ui/DynamicContainer';
import RedButtonSecondary from '../shared/ui/RedButtonSecondary';

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: '10%',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '80%',
  },
  text: {
    fontSize: '1.2rem',
    color: 'black',
    textAlign: 'center',
    lineHeight: '1.8rem',
    // marginBottom: '30px',
  },
}));

const VRminiGame = ({onCompleted}) => {
  const classes = useStyles();

  return (
    <DynamicContainer>
      <div className={classes.container}>
        <p className={classes.text}>
          Click on the button below to open a new tab where you can go on a
          Virtual Reality tour of a factory of Leminar Air Conditioning
          Industries LLC. Use the password 1971 to start the tour. Once you have
          completed the tour, you can come back to this tab to continue the
          game.
        </p>
        <RedButtonSecondary
          style={{ width: '175px', height: '50px', marginBottom: '15px' }}
        >
          <a
            style={{ textDecoration: 'none', color: 'white' }}
            href="https://360emirates.com/dubai/360-virtual-tour/Leminar-Air-Conditioning-Industries-LLC/"
            target="_blank"
            rel="noreferrer"
          >
            Start VR Tour
          </a>
        </RedButtonSecondary>
        <RedButtonSecondary style={{ width: '175px', height: '50px' }} onClick={() => onCompleted('Hi')}>
          Continue Game
        </RedButtonSecondary>
      </div>
    </DynamicContainer>
  );
};

export default VRminiGame;
