import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

import { getGameLinkType } from '../../games/iFrameGameMaster/utils/gameUtils';
import { stopAudio } from '../../games/iFrameGameMaster/utils/soundUtils';
import useStyles from './StartScreen.styles';

export default function PreGame({ onPreGameCompleted, currentTile, display }) {
  const classes = useStyles({ display });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const afterTitleMusic = useRef<HTMLAudioElement>();
  if (!afterTitleMusic.current) {
    afterTitleMusic.current = document.getElementById(
      'aftertitlemusic'
    ) as HTMLAudioElement;
  }

  const handleDialogAgree = () => {
    setDialogOpen(false);
    onPreGameCompleted(0);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleBtnClicked = (i) => {
    if (i === 0 && currentTile !== 1) {
      setDialogOpen(true);
      return;
    }

    onPreGameCompleted(i);
  };

  useEffect(() => {
    const audioElement = afterTitleMusic.current;
    if (display) {
      audioElement.loop = true;
      audioElement.volume = 1;
      audioElement.play();
    } else {
      audioElement.pause();
    }
  }, [display]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      // loop is take as a flag that video has been loaded and bg music is played on loop
      // TODO handle this in a better way, as this listener is not removed even after game is started
      if (!afterTitleMusic.current || afterTitleMusic.current.loop === false) {
        return;
      }

      if (document.visibilityState === 'hidden') {
        afterTitleMusic.current.pause();
      } else {
        afterTitleMusic.current.play();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // stop the audio and remove the event listener
      stopAudio(afterTitleMusic.current);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };

    // for iOS we don't want to show ready screen, we will just start the game
  }, []);

  useEffect(() => {
    const handleIframeEvents = (event) => {
      let data;

      if (typeof event.data === 'string') {
        data = JSON.parse(event.data);
        if (data.eventType === 'PreGame') {
          const selectedOption = data.selectedOption;
          handleBtnClicked(selectedOption);
        }
      }
    };

    window.addEventListener('message', handleIframeEvents);

    return () => {
      window.removeEventListener('message', handleIframeEvents);
    };
  }, []);

  return (
    <div>
      {/* @ts-ignore */}
      <iframe
        allow="autoplay"
        className={classes.iframe}
        src={`https://games.talentlitmus.com/startscreen${getGameLinkType()}?currTile=${currentTile}`}
        title="StartScreen"
        id="StartScreen"
        width={window.innerWidth}
        height={window.innerHeight}
      >
        {/* Start Screen will be rendered here by the cocos game */}
      </iframe>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your current progress will lost. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleDialogAgree} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/* 


  ifrm.setAttribute('src', url);
  ifrm.title = uniqueId;
  ifrm.id = uniqueId;
  ifrm.name = '' + Date.now();
  ifrm.style.display = 'block';
  ifrm.setAttribute('allow', `autoplay 'src';`);
  ifrm.setAttribute('scrolling', `auto`);
  ifrm.style.height = '100%';
  ifrm.style.width = '100%';
  ifrm.style.margin = '0';
  ifrm.style.padding = '0';
  ifrm.style.border = 'none';
  ifrm.style.overflow = 'hidden';

https://games.talentlitmus.com/startscreenmobile/
 <Grid
        container
        className={classes.root}
        justify="center"
        direction="column"
        spacing={2}
      >
    
        <div>
          <Grid item style={{marginBottom: '55px'}}>
            <img src={TakingFightImg} alt="taking flight"  style={{height: '150px'}}/>
          </Grid>

          {['New Game', 'Continue Game', 'LeaderBoard'].map((value, i) => (
            <Grid key={value} item style={{display:'flex', justifyContent:'center'}}>
              <ButtonFirst
                onClick={() => handleBtnClicked(i)}
                disabled={i === 1 && currentTile === 1}
              >
                {value}
              </ButtonFirst>
            </Grid>
          ))}
        </div>
      </Grid>
 */
//    {/* @ts-ignore */}
