import { useEffect } from 'react';

export const useSetBgWhite = () => {
  useEffect(() => {
    document.body.style.background = 'white';

    return () => {
      document.body.style.background = 'black';
    };
  }, []);
};
