import {
  Button,
  Container,
  makeStyles,
  TextField,
  withStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { useEffect, useState } from 'react';
import { useSetBgWhite } from '../../../hooks/useSetBgWhite';

export const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '10%',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    fontSize: '3rem',
    marginBottom: '30px',
  },
  button: {
    margin: theme.spacing(1),
    display: 'block',
  },
  textField: {},
}));

export default function TokenInputPage({ onFormSubmit, showError }) {
  const classes = useStyles();
  const [inputToken, setInputToken] = useState('');
  const onInputChange = (text) => {
    setInputToken(text);
  };

  useSetBgWhite();

  return (
    <Container className={classes.container}>
      <div className={classes.text}>Enter Token</div>
      <form autoComplete="off" onSubmit={(e) => onFormSubmit(e, inputToken)}>
        <TextField
          className={classes.textField}
          error={showError}
          required
          id="outlined-error-helper-text"
          label="token"
          helperText={`${showError ? 'Invalid Token' : ''}`}
          variant="outlined"
          type="text"
          value={inputToken}
          onChange={(e) => onInputChange(e.target.value)}
        />

        <ColorButton
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
        >
          Submit
        </ColorButton>
      </form>
    </Container>
  );
}
