import { makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import gameDataAPI from '../../api/gameDataAPI';
import DynamicContainer from '../shared/ui/DynamicContainer';

import LoaderCircular from '../shared/ui/Loader';
import RedButtonSecondary from '../shared/ui/RedButtonSecondary';

// export const ColorButton = withStyles((theme) => ({
//   root: {
//     color: theme.palette.getContrastText(green[500]),
//     backgroundColor: green[500],
//     '&:hover': {
//       backgroundColor: green[700],
//     },
//   },
// }))(Button);

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: '10%',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '80%'
  },
  text: {
    fontSize: '3rem',
    color: 'black',
    // marginBottom: '30px',
  },
  textField: {
    backgroundColor: 'white',
    marginBottom: '10px',
  },
  button: {
    margin: 'auto',
    display: 'block',
  },
  gameContent: {
    fontSize: '1.2rem',
    marginBottom: '20px',
    color: 'black',
  },
}));

export default function PasswordGame({ gameId, onCompleted }) {
  // console.log('password content: ', content);

  const [gameContent, setGameContent] = useState(null);

  const classes = useStyles();
  const [inputPassword, setInputPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (inputPassword.toString() === gameContent.password.toString()) {
      onCompleted();
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    document.body.style.background = 'white';

    const loadPasswordGameData = async () => {
      try {
        const data = await gameDataAPI.getPasswordGameById(gameId);
        console.log('Password game data is ', data);
        setGameContent(data);
      } catch (error) {
        console.log('Error fetching password game data', error);
      }
    };

    loadPasswordGameData();

    return () => {
      document.body.style.background = 'black';
    };
  }, []);

  const onInputChange = (inputPass: string) => {
    setShowError(false);
    setInputPassword(inputPass);
  };

  if (!gameContent) {
    return <LoaderCircular />;
  }

  return (
    <DynamicContainer>
      <div className={classes.container}>
        <div className={classes.text}>Enter Password</div>
        <p className={classes.gameContent}>{gameContent.text}</p>
        <form autoComplete="off" onSubmit={(e) => onFormSubmit(e)}>
          <TextField
            className={classes.textField}
            error={showError}
            required
            id="outlined-error-helper-text"
            label="Password"
            helperText={`${showError ? 'Invalid Password' : ''}`}
            variant="outlined"
            type="password"
            value={inputPassword}
            onChange={(e) => onInputChange(e.target.value)}
          />

          <RedButtonSecondary className={classes.button}>
            Submit
          </RedButtonSecondary>
        </form>
      </div>
    </DynamicContainer>
  );
}
