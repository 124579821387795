import { styled } from '@material-ui/core';
import ButtonBg from '../../../../assets/images/ButtonRed3.png';

const RedButtonSecondary = styled('button')({
  color: 'white',
  fontSize: '1.2rem',
  borderRadius: '15px',
  background: `url(${ButtonBg})`,
  minWidth: '120px',
  minHeight: '40px',
  border: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',

  objectFit: 'contain',
  transition: '0.2s all',
  outline: 'none',
  boxShadow: '7px 6px 28px 1px rgba(0, 0, 0, 0.24)',

  '&:active': {
    transform: 'scale(0.98)',
    boxShadow: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)',
  },
});

export default RedButtonSecondary;
