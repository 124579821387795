

// export const API_URL = "https://www.staging.adminv2.talentlitmus.com";
export const API_URL = 'https://api.adminv2.talentlitmus.com';

export const baseGameUrl = 'https://games.talentlitmus.com';

export const urlsStart = {
  JumblesGame: 'jumbles',
  SJTGame: 'sjt',
  ClassificationGame: 'classification',
  MatchColumnGame: 'mtc',
  OrderingsGames: 'ordering',
  QuestionsGame: 'questions',
};

// const linkType = getGameLinkType();

// class GameURLS {
//   linkType = linkType;
//   startScreen(currentTile) {
//     return `https://games.talentlitmus.com/startscreen${this.linkType}?currTile=${currentTile}`;
//   }
// }
