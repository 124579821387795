import axios, { AxiosInstance } from 'axios';

import { TMasterGame } from '../interfaces/gameInterfaces';
import {
  TGameOverPayLoad,
  TUpdateUserProgress,
  TUserData,
} from '../interfaces/userInterface';
import { GetSortOrder } from '../utils/getSortedOrder';

class GameAPIService {
  readonly httpClient: AxiosInstance = null;

  private static instance: GameAPIService;

  private constructor() {
    console.log('Process env -->', process.env);
    let baseURL = process.env.REACT_APP_API_URL;

    this.httpClient = axios.create({
      baseURL: baseURL,
      headers: { 'Api-key': process.env.REACT_APP_APIKEY },
    });
  }

  public static initialize(): GameAPIService {
    if (!GameAPIService.instance) {
      GameAPIService.instance = new GameAPIService();
    }
    return GameAPIService.instance;
  }

  cachedSJTGameData = new Map<string, any>();

  cachedMasterGameData = new Map<string, any>();

  cachedVideosData = new Map<string, any>();

  cachedQuestionGameData = new Map<string, any>();

  async fetchSjtGame(id: string) {
    const { data } = await this.httpClient.get(`/sjt-game/${id}`);
    data.sjtGameData = data.sjtGameData.sort(GetSortOrder('order'));
    this.cachedSJTGameData.set(id, data);
    return data;
  }

  async fetchQuestionGame(id: string) {
    const { data } = await this.httpClient.get(`/questions-game/${id}`);
    this.cachedQuestionGameData.set(id, data);
    return data;
  }

  async fetchMasterGame(id: string) {
    const { data } = await this.httpClient.get<{ games: TMasterGame[] }>(
      `/master-game/${id}`
    );
    this.cachedMasterGameData.set(id, data);
    return data;
  }
  async fetchVideoData(id: string) {
    const { data } = await this.httpClient.get(`/videos/${id}`);

    this.cachedVideosData.set(id, data);
    return data;
  }

  async fetchMatchColumnGames(id: string) {
    const { data } = await this.httpClient.get(`/match-column-game/${id}`);
    // I am not doing the caching for now
    return data;
  }

  async fetchClassificationGames(id: string) {
    const { data } = await this.httpClient.get(`/classification-game/${id}`);
    return data;
  }

  async fetchJumbleGames(id: string) {
    const { data } = await this.httpClient.get(`/jumbles-game/${id}`);
    return data;
  }

  async fetchUserData(token: string) {
    const { data } = await this.httpClient.get<TUserData>(
      `/orientation-invites/${token}`
    );
    return data;
  }

  async updateProgress(body: TUpdateUserProgress) {
    const { data } = await this.httpClient.post(
      `/orientation-invites/update`,
      body
    );
    return data;
  }

  async gameOver(body: TGameOverPayLoad) {
    const { data } = await this.httpClient.post(`/players/update`, body);
    return data;
  }

  async fetchOrderingGames(id: string) {
    const { data } = await this.httpClient.get(`/orderings-game/${id}`);
    return data;
  }

  async getMusicAssets(id: string) {
    const { data } = await this.httpClient.get(
      `/master-game/assets/music/${id}`
    );
    return data;
  }

  async fetchPdfData(id: string) {
    const { data } = await this.httpClient.get(`/pdf/${id}`);
    return data;
  }

  async getMiniGameById(id: string) {
    const { data } = await this.httpClient.get<TMiniGameResponse>(
      `/mini-games/${id}`
    );
    return data;
  }

  async getPasswordGameById(id: string) {
    const { data } = await this.httpClient.get(`/password-game/${id}`);
    return data;
  }
}

export default GameAPIService.initialize();

interface TMiniGameResponse {
  createdAt: string;
  isDeleted: boolean;
  name: string;
  updatedAt: string;
  _id: string;
  mobileUrl?: string;
  webUrl?: string;
}
