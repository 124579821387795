import { Button, styled } from '@material-ui/core';
import React from 'react';

export const CloseButton = styled(Button)({
  position: 'fixed',
  top: '20',
  right: '2%',
  zIndex: 999,
  fontSize: '0.80rem',
});
