import { makeStyles } from '@material-ui/core';
import HeroBackGround from './../../../assets/images/BG_00224.jpg';
export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    height: '100vh',
    background: `url(${HeroBackGround})`,
  },
  iframe: {
    display: (props: any) => (props.display ? 'block' : 'none'),

    height: '100vh',
    width: '100vw',
    margin: '0',
    padding: '0',
    border: 'none',
    overflow: 'hidden',
  },
}));
