import React, { useEffect, useRef, useState } from 'react';

import gameDataAPI from './../api/gameDataAPI';

import useGameStore from '../../store/useGameStore';
import IframeGameMaster from '../games/iFrameGameMaster/IframeGameMaster';

import { getDeviceInfo } from '../../hooks/useDeviceInfo2';
import StartScreen from './start-screen/StartScreen';
import { notifyError } from '../../shared/Notifications';
import LoaderCircular from '../components/shared/ui/Loader';
import ButtonFirst from './pages-ui/ButtonFirst';
import { makeStyles } from '@material-ui/core';

import LeaderBoard from '../games/leaderBoard/LeaderBoard';
import FirstVideo from '../components/videos/FirstVideo';
import HeroBackGround from './../../assets/images/FirstScreen.png';
import { getGameLinkType, getVideoDimensions } from '../games/iFrameGameMaster/utils/gameUtils';


const LeaderBoardGameId = '6066f5d109638a0012d6db61';
const LeaderBoardUrl = `https://games.talentlitmus.com/leaderboard${getGameLinkType()}/?myParam=${LeaderBoardGameId}`;

let { iHeight, iWidth, paddingTop } = getVideoDimensions();

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'black',
    display: 'flex',
    // alignItems: 'center',
    paddingTop: paddingTop,
    justifyContent: 'center',
    overflow: 'none',
    width: '100vw',
    height: '100vh',
    // height: '100vh'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: iHeight.toString() + 'px',
    width: iWidth.toString() + 'px',

    backgroundSize: 'cover',
    // background: '#202020',
    background: `url(${HeroBackGround})`,
  },
  rootMobile: {
    background: `url(${HeroBackGround})`,
    height: '100vh',
    position: 'relative',
    width: 'calc(100vh*1.777)',
    backgroundSize: 'cover',
    overflow: 'hidden',
    // background: '#202020',
  },
  btnContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function Home() {
  // const user = useGameStore((state) => state.userData);
  // const token = useGetToken();
  const gameLinkType = getGameLinkType();

  const classes = useStyles();

  const [startGame, setStartGame] = useState(false);
  /* 
  -1: none
  0: new Game
  1: Continue game
  2: Leaderboard
  */

  const [gameType, setGameType] = useState(-1);
  const setUserData = useGameStore((state) => state.setUserData);
  const userData = useGameStore((state) => state.userData);
  const playerDetails = userData.playerDetails;
  const token = useGameStore((state) => state.gameToken);
  const [isLoading, setIsLoading] = useState(false);
  // we want to play the video first thing
  const [playVideo, setPlayVideo] = useState(true);
  const afterTitleMusic = useRef<HTMLAudioElement>();
  if (!afterTitleMusic.current) {
    afterTitleMusic.current = document.getElementById(
      'aftertitlemusic'
    ) as HTMLAudioElement;
  }

  useEffect(() => {
    const device = getDeviceInfo();
    if (device === 'iOS') {
      setStartGame(true);
    }

    // for iOS we don't want to show ready screen, we will just start the game
  }, []);

  const handlePreGameComplete = (i) => {
    // new game
    if (i === 0) {
      // if current tile is not first then we will make it first
      if (playerDetails.currentBlock.tileNumber !== 1) {
        playerDetails.currentBlock.tileNumber = 1;
        playerDetails.currentBlock.completed = false;
        playerDetails.orientationScores = [];

        setIsLoading(true);
        gameDataAPI
          .updateProgress({
            token,
            orientationScores: [],
            currentBlock: {
              completed: false,

              tileNumber: 1,
            },
            gameStatus: 'in-progress',
          })
          .then((response) => {
            console.log('Update response', response);
            setUserData({ ...userData });
            setGameType(i);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            notifyError('Something went wrong please try again');
            setIsLoading(false);
          });
        // setScore(updatedScores);
      } else {
        setGameType(i);
      }

      // we can also reset the progress here in the background
    } else {
      setGameType(i);
    }
  };

  const playGameStart = () => {
    setStartGame(true);
  };

  const handleLeaderBoardDone = () => {
    setGameType(-1);
  };

  const handleVideoComplete = () => {
    setPlayVideo(false);
  };

  if (isLoading) {
    return <LoaderCircular />;
  }

  if (startGame && (gameType === -1 || gameType === 2)) {
    const LeaderBoardStartGameData = {
      cohortId: playerDetails.cohortId,
      email: playerDetails.email,
      scoreData: playerDetails.orientationScores,
    };

    return (
      <div>
        {playVideo && (
          <FirstVideo
            onVideoComplete={handleVideoComplete}
            url={'https://player.vimeo.com/video/533061122'}
          />
        )}
        <StartScreen
          display={playVideo === false && gameType === -1}
          onPreGameCompleted={handlePreGameComplete}
          currentTile={playerDetails.currentBlock.tileNumber}
        />
        <LeaderBoard
          display={playVideo === false && gameType === 2}
          url={LeaderBoardUrl}
          gameId={LeaderBoardGameId}
          startGameData={LeaderBoardStartGameData}
          onLeaderBoardDone={handleLeaderBoardDone}
        />
      </div>
    );
  }

  if (gameType === 0 || gameType === 1) {
    return <IframeGameMaster />;
  }

  return (
    <React.Fragment>
      {getDeviceInfo() !== 'iOS' && (
        <div className={classes.container}>
          <div
            className={
              gameLinkType === 'web' ? classes.root : classes.rootMobile
            }
          >
            {/* @ts-ignore */}
            <div
              className={
                gameLinkType === 'mobile' ? classes.btnContainer : null
              }
            >
              <div>
                <ButtonFirst onClick={() => playGameStart()}>
                  Play Game
                </ButtonFirst>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
