import React, { createContext, useState } from 'react';

const defaultValue = {
  isSound: true,
  setIsSound: null,
};

export const GlobalContext = createContext(defaultValue);

export const GlobalContextProvider = (props) => {
  const [isSound, setIsSound] = useState(true);
  return (
    <GlobalContext.Provider value={{ isSound, setIsSound }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
