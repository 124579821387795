import React, { useEffect, useRef } from 'react';
import { TScore } from '../../interfaces/userInterface';
import {
  createIframeElements,
  makeIframeVisible,
  sendGameStartMessage,
} from '../iFrameGameMaster/utils/iframeUtils';

interface PropsType {
  url: string;
  gameId: string;
  display: boolean;
  startGameData: {
    cohortId: string;
    email: string;
    scoreData: TScore[];
  };
  onLeaderBoardDone: () => void;
}

export default function LeaderBoard({
  url,
  gameId,
  startGameData,
  display,
  onLeaderBoardDone,
}: PropsType) {
  const iframeContainer = useRef(null);

  useEffect(() => {
    if (display) {
      const audioElement = document.querySelector(
        '#leaderBoardSound'
      ) as HTMLAudioElement;
      audioElement.volume = 1;
      audioElement.currentTime = 0.0;
      audioElement
        .play()
        .then(() => {
          console.log('leader board sound playing');
        })
        .catch((err) => {
          console.log('error playing leader board sound');
        });
    }
  }, [display]);

  useEffect(() => {
    const { container, ifrm } = createIframeElements(url, gameId);

    iframeContainer.current.appendChild(container);

    container.appendChild(ifrm);
    makeIframeVisible(container, null);

    const handleIframeEvents = (event) => {
      let data;

      if (typeof event.data === 'string') {
        data = JSON.parse(event.data);
        if (data.eventType === 'GameLoaded') {
          sendGameStartMessage(
            iframeContainer.current.firstElementChild as any,
            false,
            {
              cohortId: startGameData.cohortId,
              email: startGameData.email,
              scoreData: startGameData.scoreData,
              gameId: ''
            }
          );
        }

        if (data.eventType === 'GameCompleted' && data.gameId === gameId) {
          onLeaderBoardDone();
          return;
        }
      }
    };

    window.addEventListener('message', handleIframeEvents);

    return () => {
      window.removeEventListener('message', handleIframeEvents);
    };
  }, []);

  return (
    <div>
      <div
        ref={iframeContainer}
        style={{ display: display ? 'block' : 'none' }}
      ></div>
      <audio
        id="leaderBoardSound"
        src="https://assets.talentlitmus.com/gamesounds/rankup.wav"
      ></audio>
    </div>
  );
}
