import { useEffect } from 'react';
import create from 'zustand';

import { devtools } from 'zustand/middleware';
import shallow from 'zustand/shallow';
import { TScore, TUserData } from '../app/interfaces/userInterface';

const musicDataInitialState = {
  name: '',
  url: '',
  _id: '',
  musicVolume: '',
};

export type TMusicData = typeof musicDataInitialState;

type GameStateType = {
  isMusicPlaying: boolean;
  setIsMusicPlaying: (value: boolean) => void;
  musicData: TMusicData;
  setMusicData: (data: TMusicData) => void;
  scoreData: TScore[];
  setScore: (data: TScore[]) => void;
  userData: TUserData;
  setUserData: (data: TUserData) => void;
  deviceInfo: any;
  setDeviceInfo: (data: any) => void;
  musicUrls: string[];
  setMusicUrls: (urls: string[]) => void;
  gameToken: string;
  setGameToken: (token: string) => void;
};
type SelectorTypes =
  | 'masterGameData'
  | 'setMasterGameData'
  | 'setMusicData'
  | 'isMusicPlaying'
  | 'musicData'
  | 'setIsMusicPlaying';

const useGameStore = create<GameStateType>(
  devtools(
    (set) => ({
      userData: null,
      setUserData: (user) =>
        set({
          userData: user,
          scoreData: user.playerDetails.orientationScores,
        }),
      isMusicPlaying: true,
      setIsMusicPlaying: (data) => set({ isMusicPlaying: data }),
      musicData: musicDataInitialState,
      setMusicData: (data) => set({ musicData: data }),
      scoreData: [],
      setScore: (data) => set({ scoreData: data }),
      deviceInfo: null,
      setDeviceInfo: (data) => set({ deviceInfo: data }),
      musicUrls: [],
      setMusicUrls: (data) => set({ musicUrls: data }),
      gameToken: '',
      setGameToken: (token) => set({ gameToken: token }),
    }),
    'GameState'
  )
);

export const useGameStoreSelector = (selectors: SelectorTypes[]): any => {
  return useGameStore(
    (state) => selectors.map((value) => state[value]),
    shallow
  );
};

type TdataSetMusic = {
  musicId: {
    _id: string;
    name: string;
    url: string;
  };
  musicVolume: string;
} | null;

// use it if we know that data will be in the given format
export const useSetMusic = (data: TdataSetMusic, isIntroSequence?: boolean) => {
  const setMusicData = useGameStore((state) => state.setMusicData);
  useEffect(() => {
    if (data && data.musicId && data.musicId._id) {
      // if the intro sequence is running the we don't want to play the music
      // rather let it play the introSequence music
      if (isIntroSequence !== undefined && isIntroSequence === true) {
        return;
      }

      setMusicData({
        _id: data.musicId._id,
        url: data.musicId.url,
        name: data.musicId.name,
        musicVolume: data.musicVolume,
      });
    }
  }, [data, isIntroSequence, setMusicData]);
};

export default useGameStore;
