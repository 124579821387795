import { getDeviceInfo } from "../../../../hooks/useDeviceInfo2";

export const getScreenOrientation = () => {
    switch (window.orientation) {
      case 0:
        return 'portrait';
  
      case -90:
        return 'landscape';
  
      case 90:
        return 'landscape';
  
      case 180:
        return 'landscape';
    }
  };
  export const lockOrientation = () => {
    return window.screen.orientation.lock('landscape-secondary');
  };
  
  export const isFullScreen = () => {
    return !(!window.screenTop && !window.screenY);
  };
  
  export const setFullScreen = async () => {
    // we only want full screen functionality for android
    if (getDeviceInfo() === 'Android') {
      await makeFullScreen();
      await lockOrientation();
    }
  };
  
  export const makeFullScreen = () => {
    // we don't want full screen for iOS
    if (!isFullScreen()) {
      const fullScreenFunction =
        (document.body as any).requestFullscreen ||
        (document.body as any).webkitRequestFullScreen ||
        (document.body as any).mozRequestFullScreen ||
        (document.body as any).msRequestFullscreen;
  
      return fullScreenFunction.call(document.body);
    } else {
      return Promise.resolve();
    }
  };
  