let currentlyPlayingUrls = [];

export const soundsInfo: TSoundInfo = {};

export const pausePlayingAudios = () => {
  currentlyPlayingUrls = [];

  document.querySelectorAll('audio').forEach((elem) => {
    // if current Time is greater then 0, then most possible audio is playing, so we pause it.
    // also current Time need to be less then the duration of the sound
    if (elem.currentTime > 0 && elem.currentTime < elem.duration) {
      elem.pause();
      currentlyPlayingUrls.push(elem.src);
    }
  });
};

export const playPausedAudios = (muted) => {
  currentlyPlayingUrls.forEach((url) => {
    const audioEl = document.querySelector(
      `audio[src='${url}']`
    ) as HTMLAudioElement;

    audioEl.play();
    audioEl.muted = muted;
  });

  currentlyPlayingUrls = [];
};

export const stopAudio = (element: HTMLAudioElement) => {
  element.pause();
  element.currentTime = 0.0;
};

export const muteAllAudio = () => {
  document.querySelectorAll('audio').forEach((elem) => {
    elem.muted = true;
  });
};

export const unMuteAllAudio = () => {
  document.querySelectorAll('audio').forEach((elem) => {
    elem.muted = false;
  });
};

export const stopAllAudio = () => {
  document.querySelectorAll('audio').forEach((elem) => {
    stopAudio(elem);
  });

  for (let sound in soundsInfo) {
    soundsInfo[sound].currentlyPlaying = false;
  }
};

interface TSoundInfo {
  [url: string]: {
    currentlyPlaying: boolean;
  };
}
