import React, { useEffect } from "react";

import useGameStore from "../../../store/useGameStore";
import AfterTileMusic from "./../../../assets/sounds/AfterTitleScreen.mp3";

const gameSoundUrls = [
  "https://assets.talentlitmus.com/gamesounds/LightningSound.mp3",
  "https://assets.talentlitmus.com/gamesounds/PointsFinishedSound.mp3",
  "https://assets.talentlitmus.com/gamesounds/PointsTweenSound.mp3",
  "https://assets.talentlitmus.com/gamesounds/jumble-correct.mp3",
  "https://assets.talentlitmus.com/gamesounds/jumble-incorrect.mp3",
  "https://assets.talentlitmus.com/gamesounds/jumblesmatchsjtword-completed.mp3",
  "https://assets.talentlitmus.com/gamesounds/questionClassificationordaringsjtincorrect.mp3",
  "https://assets.talentlitmus.com/gamesounds/questionClassificationordearingsjtcorrect.mp3",
  "https://assets.talentlitmus.com/gamesounds/timer-10-seconds.mp3",
  "https://assets.talentlitmus.com/gamesounds/packing-pack.mp3",
  "https://assets.talentlitmus.com/gamesounds/packing-wrong.mp3",
  // 'https://assets.talentlitmus.com/gamesounds/sneaky-adventure-by-kevin-macleod.mp3',
  "https://assets.talentlitmus.com/gamesounds/rankup.wav",
  "https://assets.talentlitmus.com/gamesounds/PuzzleComplete.mp3",
  "https://assets.talentlitmus.com/gamesounds/PuzzleSwap.mp3",
  "https://assets.talentlitmus.com/gamesounds/PuzzleTap.mp3",
  "https://assets.talentlitmus.com/gamesounds/rankup.wav",
  "https://assets.talentlitmus.com/gamesounds/MMAMbient4-1617521800489.mp3",
  "https://assets.talentlitmus.com/gamesounds/click-blip.mp3",
  "https://assets.talentlitmus.com/gamesounds/thinkingmusic_shortloop+(1).mp3",
  "https://assets.talentlitmus.com/gamesounds/Boar+Sound.mp3",
  "https://assets.talentlitmus.com/gamesounds/Game+Music+.mp3",
  "https://assets.talentlitmus.com/gamesounds/bathroom-closet-door-creak_zk5ogs4O_NWM.mp3",
 "https://assets.talentlitmus.com/gamesounds/366102__original-sound__confirmation-upward+(mp3cut.net).wav",  

];

export default function SoundElements() {
  const musicUrls = useGameStore((state) => state.musicUrls);

  useEffect(() => {
    var audioContext = new (window.AudioContext ||
      window["webkitAudioContext"])();
    function loadSound(filename) {
      var sound = { volume: 1, audioBuffer: null };

      var ajax = new XMLHttpRequest();
      ajax.open("GET", filename, true);
      ajax.responseType = "arraybuffer";
      ajax.onload = function () {
        audioContext.decodeAudioData(
          ajax.response,
          function (buffer) {
            sound.audioBuffer = buffer;
          },
          function (error) {
            debugger;
          }
        );
      };

      ajax.onerror = function () {
        debugger;
      };

      ajax.send();

      return sound;
    }
    function playSound(sound) {
      if (!sound.audioBuffer) return false;

      var source = audioContext.createBufferSource();
      if (!source) return false;

      source.buffer = sound.audioBuffer;
      if (!source.start) source.start = source.noteOn;

      if (!source.start) return false;

      var gainNode = audioContext.createGain();
      gainNode.gain.value = sound.volume;
      source.connect(gainNode);
      gainNode.connect(audioContext.destination);

      source.start(0);

      sound.gainNode = gainNode;
      return true;
    }
    function stopSound(sound) {
      if (sound.gainNode) sound.gainNode.gain.value = 0;
    }
    function setSoundVolume(sound, volume) {
      sound.volume = volume;

      if (sound.gainNode) sound.gainNode.gain.value = volume;
    }
    // alert('Hi there')
    // How to use:
    var mySound = loadSound(
      "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
    );
    // Then later after audio is unlocked and the sound is loaded:
    playSound(mySound);
    // How to unlock all sounds:
    var emptySound = loadSound(
      "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
    );
    document.body.addEventListener(
      "load",
      function () {
        playSound(emptySound);
      },
      false
    );
  }, []);

  return (
    <div id="audioFiles">
      <audio
        className="JumblesCorrectSound"
        src="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
      ></audio>
      <audio id="aftertitlemusic" src={AfterTileMusic}></audio>

      {gameSoundUrls.map((url, index) => (
        <audio key={index} src={url} muted></audio>
      ))}

      {musicUrls.map((url, index) => (
        <audio key={index} src={url} muted></audio>
      ))}
    </div>
  );
}
