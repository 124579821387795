import React from 'react';
import { useSetBgWhite } from '../../../hooks/useSetBgWhite';

export default function ErrorPage({ text }) {
  useSetBgWhite();

  return (
    <div>
      <div>
        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-red-400 sm:text-4xl">
              <span className="block">🙁 {text}</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
//Invalid Token
