import { TScore } from '../../../interfaces/userInterface';

export const sendGameStartMessage = (
  parentOfNext: HTMLDivElement,
  isMuted: boolean,
  data: { cohortId: string; email: string; scoreData: TScore[], gameId: string },
  isFirst: boolean = false
) => {
  // const iframeElement = parentOfNext.firstChild as HTMLIFrameElement;
  const iframeElement:any = document.getElementById(data.gameId)  || parentOfNext.firstChild  ;
  console.log('Send start game message', iframeElement);
  console.log('sendGameStartMessage called for ', iframeElement.src);
  iframeElement.contentWindow.postMessage(
    JSON.stringify({
      error: false,
      event: 'gamestart',
      currentid: iframeElement.id,
      isMuted,
      isFirst,
      cohortId: data.cohortId,
      email: data.email,
      scoreData: data.scoreData,
    }),
    '*'
  );
};

export const createIframeElements = (url, uniqueId) => {
  
  console.log('Creating iframe elements for url: ', url);
  
  var container = document.createElement('div');
  container.style.overflow = 'hidden';
  container.style.position = 'absolute';
  container.style.opacity = '0';
  container.style.zIndex = '-1';
  container.style.bottom = '0';
  container.style.right = '0';
  container.style.height = '1px';

  const ifrm = document.createElement('iframe');
  ifrm.setAttribute('src', url);
  ifrm.title = uniqueId;
  ifrm.id = uniqueId;
  ifrm.name = '' + Date.now();
  ifrm.style.display = 'block';
  ifrm.setAttribute('allow', `autoplay 'src';`);
  ifrm.setAttribute('scrolling', `auto`);
  ifrm.style.height = '100%';
  ifrm.style.width = '100%';
  ifrm.style.margin = '0';
  ifrm.style.padding = '0';
  ifrm.style.border = 'none';
  ifrm.style.overflow = 'hidden';

  return {
    container,
    ifrm,
  };
};

export const makeIframeVisible = (parentOfNext: HTMLDivElement, gameId) => {
  const currElement = document.getElementById(gameId);
  if(currElement){
    parentOfNext = currElement.parentElement as HTMLDivElement;
  }
  parentOfNext.style.width = '100vw';
  parentOfNext.style.height = '100vh';
  parentOfNext.style.opacity = '1';
  parentOfNext.style.zIndex = '1';
  parentOfNext.style.top = '0';
  parentOfNext.style.left = '0';
};
